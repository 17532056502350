exports.components = {
  "component---node-modules-gatsby-plugin-jaen-admin-page-tsx": () => import("./../../../node_modules/gatsby-plugin-jaen/AdminPage.tsx" /* webpackChunkName: "component---node-modules-gatsby-plugin-jaen-admin-page-tsx" */),
  "component---node-modules-gatsby-plugin-jaen-login-page-tsx": () => import("./../../../node_modules/gatsby-plugin-jaen/LoginPage.tsx" /* webpackChunkName: "component---node-modules-gatsby-plugin-jaen-login-page-tsx" */),
  "component---src-jaen-templates-article-tsx": () => import("./../../../src/jaen-templates/Article.tsx" /* webpackChunkName: "component---src-jaen-templates-article-tsx" */),
  "component---src-jaen-templates-kategorie-a-tsx": () => import("./../../../src/jaen-templates/KategorieA.tsx" /* webpackChunkName: "component---src-jaen-templates-kategorie-a-tsx" */),
  "component---src-jaen-templates-kategorie-b-tsx": () => import("./../../../src/jaen-templates/KategorieB.tsx" /* webpackChunkName: "component---src-jaen-templates-kategorie-b-tsx" */),
  "component---src-jaen-templates-wissen-article-page-tsx": () => import("./../../../src/jaen-templates/WissenArticlePage.tsx" /* webpackChunkName: "component---src-jaen-templates-wissen-article-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-grosshandel-tsx": () => import("./../../../src/pages/grosshandel.tsx" /* webpackChunkName: "component---src-pages-grosshandel-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-wissen-tsx": () => import("./../../../src/pages/wissen.tsx" /* webpackChunkName: "component---src-pages-wissen-tsx" */),
  "component---src-templates-product-page-template-tsx": () => import("./../../../src/templates/ProductPageTemplate.tsx" /* webpackChunkName: "component---src-templates-product-page-template-tsx" */),
  "component---src-templates-products-page-template-tsx": () => import("./../../../src/templates/ProductsPageTemplate.tsx" /* webpackChunkName: "component---src-templates-products-page-template-tsx" */)
}

