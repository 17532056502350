module.exports = [{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"showSpinner":false,"trickle":false,"minimum":0.4},
    },{
      plugin: require('../node_modules/gatsby-plugin-jaen/gatsby-browser.tsx'),
      options: {"plugins":[],"snekResourceId":"63571eee-f41c-4745-9130-d746c2cb97a3"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
